$brand: "ggl";
.root {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 1rem;
  background-color: var(--ggl-panel-bg);
  height: 1000px;
}

.panel {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.searchHeader {
  display: grid;
  grid-template-areas: "search-bar add-cardholder-button";
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: 1rem 1rem 2rem 1rem;
  background-color: var(--ggl-panel-bg);
}

.form {
  grid-area: search-bar;
  box-sizing: border-box;
  width: 100%;
}

.search {
  max-width: 25rem;
}

.addButton {
  grid-area: add-cardholder-button;
  justify-self: end;
}

.loadButton {
  text-align: center;
  padding: 1rem;
}

@media only screen and (max-width: 860px) {
  .root {
    padding: 0;
  }

  .searchHeader {
    padding: 1.5rem 0.5rem 1rem 0.5rem;
    grid-template-areas:
      "search-bar"
      "add-cardholder-button";
    grid-template-columns: 1fr;
    grid-template-rows: 1fr min-content;
    position: fixed;
    z-index: 98;
    width: 100%;
    row-gap: 0.5rem;
  }

  .form {
    grid-row: 1;
    background-color: var(--ggl-panel-bg);
  }

  .search {
    max-width: 100%;
  }

  .addButton {
    grid-column: 1;
    grid-row: 2;
    justify-self: center;
  }

  .panel {
    padding: 0;
    margin-top: 8.5rem;
  }

  .results {
    thead,
    tbody {
      display: block;
    }
    thead {
      position: fixed;
      width: 100%;
      background-color: var(--ggl-panel-bg);
      padding: 0 0.5rem 0.5rem 0.5rem;
      z-index: 99;
    }
    tbody {
      margin-top: 3rem;
      z-index: -1;
    }
    tr {
      display: flex;
      align-items: center;
      width: 100%;
    }
    th {
      &:first-child {
        display: block;
        width: 100%;
        height: 3rem;
        color: transparent;
        &:before {
          content: "Full Name";
          color: var(--ggl-text-color);
        }
      }
      &:nth-child(n + 2) {
        display: none;
      }
    }
    td {
      &:first-child {
        flex-basis: 3.5rem;
        padding: 0;
        padding-right: 1rem;
        margin-left: 0.5rem;
      }
      &:nth-child(n + 2),
      &:nth-child(n + 3) {
        width: fit-content;
        margin-right: 0.5rem;
      }
      &:nth-child(n + 4) {
        display: none;
      }
    }
  }
}
