$brand: "ggl";
.root {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 1rem;
  padding-top: 0;
  background-color: var(--ggl-panel-bg);

  --search-page-header-padding-top: 2rem;
  --search-page-header-padding-bottom: 2rem;
  --search-page-searchbar-height: var(--ggl-control-item-height);
  --search-page-header-top: var(--header-height);
  //prettier-ignore
  --search-page-header-height: calc(
    var(--search-page-header-padding-top) + 
    var(--search-page-searchbar-height) +
    var(--search-page-header-padding-bottom)
  );
  --search-page-table-header-top: calc(var(--header-height) + var(--search-page-header-height));
}

.panel {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.searchHeader {
  display: grid;
  grid-template-areas: "search-bar add-cardholder-button";
  grid-template-columns: 1fr max-content;
  align-items: center;
  padding: var(--search-page-header-padding-top) 1rem var(--search-page-header-padding-bottom) 1rem;
  background-color: var(--ggl-panel-bg);
  position: sticky;
  top: var(--search-page-header-top);
  z-index: calc(var(--table-body-z-index) + 1);
}

.searchForm {
  margin-right: 1rem;
}

.addButton {
  grid-area: add-cardholder-button;
  justify-self: end;
}

.loadButton {
  text-align: center;
  padding: 1rem;
}

.table {
  /*
  making each cell the same width
  */
  table-layout: fixed;

  td:first-child,
  th:first-child {
    padding: 0 0.5rem;
  }

  thead {
    z-index: var(--title-z-index);
  }

  tbody {
    z-index: var(--table-body-z-index);
  }

  /*
   * Rounded corners - we have to use box-shadow because border does not work with sticky
   */
  thead > tr {
    //prettier-ignore
    box-shadow: -1px 0 0 0 var(--ggl-control-border-light) inset, // left
                0 -1px 0 0 var(--ggl-control-border-light) inset, // top
                1px 0 0 0 var(--ggl-control-border-light) inset,  // right
                0 1px 0 0 var(--ggl-control-border-light) inset; //bottom
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  tbody > tr {
    cursor: pointer;
    //prettier-ignore
    box-shadow: -1px 0 0 0 var(--ggl-control-border-light) inset, // left
                1px 0 0 0 var(--ggl-control-border-light) inset,  // right
                0 -1px 0 0 var(--ggl-control-border-light) inset; // bottom
  }

  tbody > tr:last-of-type {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  tbody > tr:first-child {
    border-top: none;
  }

  .openInNewIcon {
    width: 2.5rem;
    padding: 0 0.5rem;
  }

  tbody > tr > td {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  thead > tr > th > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
  }

  tbody > tr > td:nth-last-child(2) {
    padding-right: 0.5rem;
  }

  tbody > tr > td:last-child {
    svg {
      display: none;
    }
    padding: 0 0.5rem;
    width: 1rem;
  }

  tbody > tr:hover {
    background-color: var(--ggl-panel-bg-alt);
    td:last-child svg {
      display: block;
      height: 1rem;
      width: 1rem;
      fill: var(--ggl-text-faint);
    }
  }

  td {
    vertical-align: middle;
  }

  figure {
    cursor: pointer;
  }

  /** Fixed columns */
  thead {
    position: sticky;
    top: var(--search-page-table-header-top);
  }
}

@media only screen and (max-width: 480px) {
  .root {
    padding: 0;
    --search-page-header-padding-top: 1rem;
    --search-page-header-padding-bottom: 1rem;
    --search-page-searchbar-height: var(--ggl-control-item-height);
    --search-page-add-cardholder-height: var(--ggl-control-item-height);
    --search-page-header-top: var(--header-height);
    //prettier-ignore
    --search-page-header-height: calc(
      var(--search-page-header-padding-top) + 
      var(--search-page-searchbar-height) +
      var(--search-page-header-padding-bottom) + 
      var(--search-page-add-cardholder-height) +
      var(--search-page-header-padding-bottom)
    );
    --search-page-table-header-top: calc(var(--header-height) + var(--search-page-header-height));
  }

  .searchHeader {
    padding: 1rem 1rem;
    grid-template-areas:
      "search-bar"
      "add-cardholder-button";
    grid-template-columns: 1fr;
    grid-template-rows: 1fr min-content;
    position: sticky;
    z-index: 98;
    width: 100%;
    row-gap: 1rem;
  }

  .searchForm {
    margin-right: 0;
  }

  .addButton {
    grid-column: 1;
    grid-row: 2;
    justify-self: center;
  }
}
