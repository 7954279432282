$brand: "ggl";
.form {
  display: grid;
  column-gap: 1rem;
  grid-template-columns: 1fr auto;
  background-color: var(--ggl-panel-bg);
  max-width: 30rem;
}

.search {
  min-width: 0;
}

.filterDropdown {
  margin: 1rem;
}

@media only screen and (min-width: 480px) {
  .form {
    grid-template-columns: 2fr minmax(0, 1fr);
  }

  .filterDropdown {
    align-self: flex-start;
    grid-column: 2;
    margin: 0;
  }
}
